<template>
	<div class="col-sm-8">
		<e-select
			:id="code"
            :name="code"
			v-model="selected"
			:options="options"
			:label="groupValuesLabel"
			:track-by="uniqueIdentifier"
			:group-label="groupLabel"
			:group-values="groupValues"
			:show-labels="false"
			:close-on-select="false"
			searchable
			allow-empty
			multiple
		>
			<template slot="option" slot-scope="props">{{ translate(props.option) }}</template>
			<template slot="tag" slot-scope="{ option }">
				<div class="multiselect__tag">	
					{{ translate(option) }}
					<i class="multiselect__tag-icon" @mousedown.prevent="removeTag(option)" />
				</div>
			</template>
            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
		</e-select>
	</div>
</template>

<script type="text/javascript">
	export default {
		name: "MultiSelectInput",
		props:[
			'values',
			'answer',
			'q_id',
			'answer_given',
            'code'
		],
		data() {
			return {
				options: [],
				local_values: null,
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				if(typeof(this.values) == "string") {
					this.local_values = JSON.parse(this.values)
				}
				else {
					this.local_values = this.values
				}

				if(this.local_values.type == "data") {
					this.options = this.local_values.data
				}

				this.init_input()
			},
			init_input() {
				if(this.answer_given && this.answer_given.val !== null) {
					this.selected = this.answer_given.val
				}
			},
			resetCheck() {
				this.selected = []
			},
			translate(option) {
				if(option.includes('Collecté le')) {
					option = option.replace('Collecté le', this.getTrad('monte.collecte'))
				}
				if(option.includes('Congelé')) {
					option = option.replace('Congelé', this.getTrad('monte.frozen'))
				}

				return option
			},
			removeTag(option){
				const index = this.selected.indexOf(option)
				return index > -1 ? this.selected.splice(index, 1) : null
			}
		},
		computed: {
			selected: {
				set(val) {
					this.$emit('update:answer', { key: this.q_id, val: val })
				},
				get() {
					return this.answer.val
				}
			},
			// Si on a un attribut groupLabel ça veut dire qu'on groupe le e-select
			groupLabel() {
				if(this.values.data.length > 0) {
					return this.values.data[0].groupLabel ? 'groupLabel' : undefined
				}

				return undefined
			},
			groupValues() {
				if(this.values.data.length > 0) {
					return this.values.data[0].values ? 'values' : undefined
				}

				return undefined
			},
			groupValuesLabel() {
				if(this.values.data.length > 0) {
					return this.values.data[0].values ? 'value' : undefined
				}

				return undefined
			},
			uniqueIdentifier() {
				if(this.values.data.length > 0) {
					return this.values.data[0].values ? 'uniqueIdentifier' : undefined
				}

				return undefined
			}
		}
	}
</script>
