<template>
    <div class="col-sm-8">
            <!-- :id="'checkbox-group-'+q_id" -->
        <b-form-checkbox
            :id="code"
            :name="code"
            v-model="checked"
            :required="required"
        >
        </b-form-checkbox>
    </div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import Navigation from '@/mixins/Navigation.js'

	export default {
		name: "CheckboxInput",
        mixins: [Navigation],
        props: [
            'placeholder',
            'q_id',
            'answer_given',
            'required',
            'code'
        ],
        data() {
            return {
                checked: '',
                events_tab: {
                    'inputs::resetChecked': this.resetCheck
                }
            }
        },
        mounted() {
            // On passe checked à false ici, pour déclencher le watcher, et avoir par défaut une valeur à false qui remonte pour cette question
            this.checked = false

            this.init_component()
        },
        methods: {
            init_component() {
                if(this.answer_given && this.answer_given.val!==null && this.answer_given.key == this.q_id) {
                    this.checked = this.answer_given.val[0]
                }
            },
            resetCheck() {
                this.checked = false
            }
        },
        watch: {
            'checked' (val, prev) {
                this.$emit('update:answer', { key: this.q_id, val: val })
            }
        }
    }
</script>
