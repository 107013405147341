import Common from '@/assets/js/common.js'
import Horse from "@/mixins/Horse.js"
import Gynecologie from "@/mixins/Gynecologie.js"
import Constants from '../../config.local.js'
import PlanningMonte from "@/mixins/PlanningMonte.js"
import Lieux from "@/mixins/Lieux.js"
import Vue from 'vue'
import Countries from '@/assets/lang/countries/countries'

var LocalWBSMixin = {
    mixins: [Horse, Gynecologie, PlanningMonte, Lieux],
    methods: {
        resolveWebservicesInterfaces(name, question_webservice, params, preselect) {
            switch (name) {
				case 'embryonsAvailable':
					return this.embryonsAvailable(question_webservice, params)
                case 'horseStallion':
                    return this.horseStallion(question_webservice, params, preselect)
                case 'laboratoire':
                    return this.laboratoire(question_webservice, params, preselect)
                case 'matrice':
                    return this.matrice(question_webservice, params, preselect)
				case 'pays':
					return this.pays(question_webservice, params)
                case 'resultat_analyse':
                    return this.resultat_analyse(question_webservice, params, preselect)
                case 'interpretation':
                    return this.interpretation(question_webservice, params, preselect)
				case 'seasonMareStallion':
					return this.seasonMareStallion(question_webservice, params)
				case 'seasonMareStallionPorteuse':
					return this.seasonMareStallionPorteuse(question_webservice, params)
				case 'seasonMareStallionPorteuseOrNot':
					return this.seasonMareStallionPorteuseOrNot(question_webservice, params)
				case 'seasonMareStallionPorteusePrevious':
					return this.seasonMareStallionPorteusePrevious(question_webservice, params)
				case 'seasonMareStallionPrevious':
					return this.seasonMareStallionPrevious(question_webservice, params)
				case 'seasonMareStallionPorteuseMaybePrevious':
					return this.seasonMareStallionPorteuseMaybePrevious(question_webservice, params)
				case 'lieu':
					return this.lieu(question_webservice, params)
                default:
                    console.error("LocalWBSMixin Error => Cannot find corresponding webservice")
                    return []
            }
        },

		embryonsAvailable: async function(question_webservice, params) {
			const embryons = await this.getAvailableEmbryons(params.actes_id)

			question_webservice.question_embryons = {}

			question_webservice.question_questiondata['data'] = embryons.map(embryon => {
				const collecte_date = Date.parseTz(embryon.collecte.actes_date)
				let label = `#${embryon.embryon_num} - ${embryon.collecte.horse.horse_nom}`

				if(embryon.collecte.season_mare_stallion && embryon.collecte.season_mare_stallion.horse) {
					label += ` x ${embryon.collecte.season_mare_stallion.horse.horse_nom}`
				}

				label += ` - ${this.getTrad('reponses.Collecté le')} ${collecte_date.toLocaleDateString(this.$i18n.locale())}`

				if(embryon.embryon_congele) {
					label += ' - ' + this.getTrad('reponses.Congelé')
				}

				question_webservice.question_embryons[label] = embryon.embryon_id

				return label
			})

			question_webservice.question_questiontype = 'MCQ'

			return [ question_webservice ]
		},

        horseStallion: async function(question_webservice, params, preselect) {
            question_webservice.question_questiondata['data'] = []
            if(!params || !params.horses_ids) {
                return []
            }

            let horse_selected = ""
            if(params.horses_ids.length == 1 && preselect === '') {
				let season = await this.getCurrentSeason(new Date(params.actes_date))

				if(season.length) {
					season = season[0]
					const seasonMareStallion = await this.loadSeasonMareStallionBySeason(params.horses_ids[0], season.season_id)

					if(seasonMareStallion) {
						for(let i = 0; i < seasonMareStallion.length; i++) {
							let horse = seasonMareStallion[i].horse

							if(horse) {
								question_webservice.question_questiondata['data'].push(" " + horse.horse_nom)
								horse_selected = horse.horse_nom
							}
						}
					}
				}
            }
			else if(preselect !== '' ) {
                question_webservice.question_questiondata['data'].push(" " + preselect)
                horse_selected = preselect
            }

            const horsesStallion = await this.getHorsesStallion(false)
            if(horsesStallion) {
                for(let y = 0; y < horsesStallion.length; y++) {
                    if(horsesStallion[y].horse_nom.charAt(0) === ' ') {
                        horsesStallion[y].horse_nom = horsesStallion[y].horse_nom.substr(1);
                    }

                    if(horse_selected != horsesStallion[y].horse_nom) {
                        question_webservice.question_questiondata['data'].push(horsesStallion[y].horse_nom)
                    }
                }
            }

            question_webservice.question_stallion = horsesStallion

            question_webservice.question_questiondata.data.sort()

            return [ question_webservice ]
        },

        laboratoire: async function(question_webservice, params, preselect) {
            const url = this.constructRoute(Constants.ACTES_ANALYSE_LABO_URL, {acte_type: params.acte_type_code, date: params.actes_date.toDateInputValue()}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("LocalWBS::laboratoire", url)
            .catch(error => {
                console.error("LocalWBS::laboratoire => ERROR", error)
                return null
            })
            
            question_webservice.question_questiondata.data = result.retour.labo
            question_webservice.question_laboratoire = result.retour.data

            return [ question_webservice ]
        },

        matrice: async function(question_webservice, params, preselect) {
            const url = this.constructRoute(Constants.ACTES_ANALYSE_MATRICE_URL, {acte_type: params.acte_type_code}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("LocalWBS::matrice", url)
            .catch(error => {
                console.error("LocalWBS::matrice => ERROR", error)
                return null
            })
            
            question_webservice.question_questiondata.data = result.retour.matrice
            question_webservice.question_matrice = result.retour.data

            return [ question_webservice ]
        },

        pays: async function(question_webservice, params) {
            question_webservice.question_questiondata.data = Object.values(Countries[Vue.i18n.locale()].label)
            question_webservice.question_pays = Countries[Vue.i18n.locale()].label

            return [ question_webservice ]
        },

        resultat_analyse: async function(question_webservice, params, preselect) {
            const url = this.constructRoute(Constants.RESULTAT_ANALYSE_URL, {result_type: params.acte_type_code}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("LocalWBS::resultat_analyse", url)
            .catch(error => {
                console.error("LocalWBS::resultat_analyse => ERROR", error)
                return null
            })
            
            question_webservice.question_questiondata.data = result.retour.result
            question_webservice.question_resultat = result.retour.data

            return [ question_webservice ]
        }, 

        interpretation: async function(question_webservice, params, preselect) {
            const url = this.constructRoute(Constants.INTERPRETATION_ANALYSE_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("LocalWBS::interpretation", url)
            .catch(error => {
                console.error("LocalWBS::interpretation => ERROR", error)
                return null
            })
            
            question_webservice.question_questiondata.data = result.retour.interpretation
            question_webservice.question_interpretation = result.retour.data

            return [ question_webservice ]
        },

		seasonMareStallion: async function(question_webservice, params) {
			question_webservice.question_questiondata.data = []

			const mare_id = params.horses_ids.length ? params.horses_ids[0] : null
			if(mare_id) {
				// Correspondance entre le nom et le seasonmarestallion_id
				question_webservice.question_season_mare_stallions = {}

				// Charger les infos de la saison en cours
				let current_season = await this.getCurrentSeason(new Date(params.actes_date))
				if (current_season.length > 0) {
					// current_season = current_season[0]

					// const current_season_mare = await this.getCurrentSeasonMare(current_season.season_id, mare_id)

					// if(current_season_mare) {
					// 	// Charger les étalons de la saison en cours
					// 	await this.loadSeasonStallions(current_season, current_season_mare, question_webservice, true)
					// }
					await Common.asyncForEach(current_season, async (season) => {
						const current_season_mare = await this.getCurrentSeasonMare(season.season_id, mare_id)
						if(current_season_mare) {
							// Charger les étalons de la saison en cours
							await this.loadSeasonStallions(season, current_season_mare, question_webservice, true)
						}
					})
				}

				await this.loadOtherStallions(question_webservice)
			}

			return [ question_webservice ]
		},

		seasonMareStallionPorteuse: async function(question_webservice, params) {
			question_webservice.question_questiondata.data = []

			const mare_id = params.horses_ids.length ? params.horses_ids[0] : null
			if(mare_id) {
				// Correspondance entre le nom et le seasonmarestallion_id
				question_webservice.question_season_mare_stallions = {}

				let current_season = await this.getCurrentSeason(new Date(params.actes_date))
				if (current_season.length > 0) {
					// current_season = current_season[0]

					// const current_season_mare = await this.getCurrentSeasonMare(current_season.season_id, mare_id)

					// if(current_season_mare) {
					// 	// Charger les étalons de la saison en cours
					// 	await this.loadSeasonStallions(current_season, current_season_mare, question_webservice, true)
					// }

					// // Charger les couples dont elle pourrait etre la porteuse
					// await this.loadSeasonPorteuseStallions(current_season, current_season_mare, question_webservice, true)
					await Common.asyncForEach(current_season, async (season) => {
						const current_season_mare = await this.getCurrentSeasonMare(season.season_id, mare_id)
						if(current_season_mare) {
							// Charger les étalons de la saison en cours
							await this.loadSeasonStallions(season, current_season_mare, question_webservice, true)
							await this.loadSeasonPorteuseStallions(season, current_season_mare, question_webservice, true)
						}
					})
				}

				await this.loadOtherStallions(question_webservice)

			}

			if(!question_webservice.question_default_value && question_webservice.question_questiondata.data.length > 0 && question_webservice.question_questiondata.data[0].values.length > 0) {
				question_webservice.question_default_value = question_webservice.question_questiondata.data[0].values[0]
			}

			return [ question_webservice ]
		},

		seasonMareStallionPorteuseOrNot: async function(question_webservice, params) {
			question_webservice.question_questiondata.data = []

			const mare_id = params.horses_ids.length ? params.horses_ids[0] : null
			if(mare_id) {
				// Correspondance entre le nom et le seasonmarestallion_id
				question_webservice.question_season_mare_stallions = {}

				let current_season = await this.getCurrentSeason(new Date(params.actes_date))
				if (current_season.length > 0) {
					// current_season = current_season[0]

					// const current_season_mare = await this.getCurrentSeasonMare(current_season.season_id, mare_id)

					// if(current_season_mare) {
					// 	// Charger les étalons de la saison en cours
					// 	await this.loadSeasonStallionsWithPorteuse(current_season, current_season_mare, question_webservice, true)
					// }

					// // Charger les couples dont elle pourrait etre la porteuse
					// await this.loadSeasonPorteuseStallions(current_season, current_season_mare, question_webservice, true)

					await Common.asyncForEach(current_season, async (season) => {
						const current_season_mare = await this.getCurrentSeasonMare(season.season_id, mare_id)

						if(current_season_mare) {
							// Charger les étalons de la saison en cours
							await this.loadSeasonStallions(season, current_season_mare, question_webservice, true)
							await this.loadSeasonPorteuseStallions(season, current_season_mare, question_webservice, true)
						}
					})
				}

				await this.loadOtherStallions(question_webservice)

			}

			if(!question_webservice.question_default_value && question_webservice.question_questiondata.data.length > 0 && question_webservice.question_questiondata.data[0].values.length > 0) {
				question_webservice.question_default_value = question_webservice.question_questiondata.data[0].values[0]
			}

			return [ question_webservice ]
		},

		seasonMareStallionPorteusePrevious: async function(question_webservice, params) {
			question_webservice.question_questiondata.data = []

			const mare_id = params.horses_ids.length ? params.horses_ids[0] : null
			if(mare_id) {
				// Correspondance entre le nom et le seasonmarestallion_id
				question_webservice.question_season_mare_stallions = {}

				// Infos de la saison en cours
				let current_season = await this.getCurrentSeason(new Date(params.actes_date))
				let current_season_mare = null

				// if (current_season.length > 0) {
				// 	current_season = current_season[0]

				// 	current_season_mare = await this.getCurrentSeasonMare(current_season.season_id, mare_id)
				// }
				await Common.asyncForEach(current_season, async (season) => {
					current_season_mare = await this.getCurrentSeasonMare(season.season_id, mare_id)

					// Infos de la saison précédente
					let lastYearDate = new Date(params.actes_date.getTime())
					lastYearDate.setMonth(lastYearDate.getMonth() - 12)
					let previous_season = await this.getCurrentSeason(lastYearDate)
					let previous_season_mare = null

					if (previous_season.length > 0) {
						previous_season = previous_season[0]

						previous_season_mare = await this.getCurrentSeasonMare(previous_season.season_id, mare_id)
					} 
					else {
						previous_season = null
					}

					// Etalons de la saison en cours
					if (season && current_season_mare) {
						// Charger les étalons de la saison en cours
						await this.loadSeasonStallions(season, current_season_mare, question_webservice, true)
					}

					// Couples en tant que porteuse de la saison en cours
					if(season) {
						// Charger les couples dont elle pourrait etre la porteuse
						await this.loadSeasonPorteuseStallions(season, current_season_mare, question_webservice, !question_webservice.question_default_value)
					}

					// Etalons de la saison précédente
					if (previous_season && previous_season_mare) {
						// Charger les étalons de la saison en cours
						await this.loadSeasonStallions(previous_season, previous_season_mare, question_webservice, !question_webservice.question_default_value)
					}

					// Couples en tant que porteuse de la saison précédente
					if(previous_season) {
						await this.loadSeasonPorteuseStallions(previous_season, previous_season_mare, question_webservice, !question_webservice.question_default_value)
					}
				})
			}

			if(!question_webservice.question_default_value && question_webservice.question_questiondata.data.length > 0 && question_webservice.question_questiondata.data[0].values.length > 0) {
				question_webservice.question_default_value = question_webservice.question_questiondata.data[0].values[0]
			}

			return [ question_webservice ]
		},

		async getCurrentSeasonMare(season_id, mare_id) {
			return this.$storage.db.t('season_mare')
			.then(table => {
				return table.where({
					seasonmare_horse: mare_id,
					seasonmare_season: season_id
				})
				.first()
			})
		},

		async loadSeasonStallions(season, season_mare, question_webservice, select_default) {
			// Correspondance entre un horse_id(étalon) et un seasonmarestallion_id
			let horse_season_mare_stallion = {}

			const season_mare_stallions = await this.$storage.db.t('season_mare_stallion')
			.then(table => {
				return table.where({
					seasonmarestallion_seasonmare: season_mare.seasonmare_id
				})
				.and((sms) => (sms.seasonmarestallion_porteuse === null))
				.toArray()
			})

			const stallion_ids = []
			const stallion_ids_dds = []
			let default_stallion = null
			await Common.asyncForEach(season_mare_stallions, async sms => {
				horse_season_mare_stallion[sms.seasonmarestallion_horse] = sms
				stallion_ids.push(sms.seasonmarestallion_horse)
				stallion_ids_dds.push({
					horse_id: sms.seasonmarestallion_horse,
					dds: sms.seasonmarestallion_dds ? sms.seasonmarestallion_dds : ''
				})

				if(sms.seasonmarestallion_default) {
					default_stallion = sms
				}
			})

			let horses = []
			if (stallion_ids.length > 0) {
				horses = await this.$storage.db.t('horse')
				.then(table => {
					return table.where('horse_id').anyOf(stallion_ids).and(h => h.horse_inactive == 0).toArray()
				})
			}

			if (horses.length > 0) {
				const groupIdx = question_webservice.question_questiondata.data.length
				const groupLabel = season.season_label
				question_webservice.question_questiondata.data.push({
					groupLabel: groupLabel,
					values: []
				})

				horses.forEach(horse => {
					const sms = horse_season_mare_stallion[horse.horse_id]
					const id = sms.seasonmarestallion_id
					question_webservice.question_season_mare_stallions[id] = id

					let label = horse.horse_nom

					if(sms.seasonmarestallion_status) {
						label += ` / ${sms.seasonmarestallion_status}`
					}

					if(sms.seasonmarestallion_num_dps) {
						label += ` / ${sms.seasonmarestallion_num_dps}`
					}

					const elem = stallion_ids_dds.find(el => el.horse_id == horse.horse_id)
					if(elem.dds) {
						const diff = this.calculDifference(elem.dds, new Date())
						label += ` / ${diff} ` + this.getTrad('monte.days_since_last_covering')
					}

					const value = {
						uniqueIdentifier: id,
						group: groupLabel,
						value: label
					}
					question_webservice.question_questiondata.data[groupIdx].values.push(value)

					if(select_default && default_stallion && horse.horse_id === default_stallion.seasonmarestallion_horse) {
						question_webservice.question_default_value = value
					}
				})
			}
		},


		async loadSeasonStallionsWithPorteuse(season, season_mare, question_webservice, select_default) {
			// Correspondance entre un horse_id(étalon) et un seasonmarestallion_id
			let horse_season_mare_stallion = {}

			const season_mare_stallions = await this.$storage.db.t('season_mare_stallion')
			.then(table => {
				return table.where({
					seasonmarestallion_seasonmare: season_mare.seasonmare_id
				})
				.toArray()
			})

			// Récupérer les IDs des season_mare des donneuse et les IDs des étalons et construire un objet représentant les couples
			const donneuse_seasonmare_ids = []
			const porteuse_seasonmare_ids = []
			const stallion_ids = []
			let default_stallion

			season_mare_stallions.forEach(sms => {
				donneuse_seasonmare_ids.push(sms.seasonmarestallion_seasonmare)
				if(sms.seasonmarestallion_porteuse) {
					porteuse_seasonmare_ids.push(sms.seasonmarestallion_porteuse)
				}
				stallion_ids.push(sms.seasonmarestallion_horse)

				if(sms.seasonmarestallion_default) {
					default_stallion = sms
				}
			})

			// Récupérer les IDs des donneuses à partir des season_mare et se souvenir de la correspondance seasonmare_id => horse_id
			let season_mares_horses = {}
			const mare_ids = await this.$storage.db.t('season_mare')
			.then(table => {
				return table.where('seasonmare_id').anyOf(donneuse_seasonmare_ids.concat(porteuse_seasonmare_ids)).toArray()
			})
			.then(season_mares => {
				return season_mares.map((sm) => {
					season_mares_horses[sm.seasonmare_id] = sm.seasonmare_horse

					return sm.seasonmare_horse
				})
			})

			if (mare_ids.length > 0 || stallion_ids.length > 0) {
				// Récupérer tous les chevaux
				const horses = await this.$storage.db.t('horse')
				.then(table => {
					return table.where('horse_id').anyOf(stallion_ids.concat(mare_ids)).toArray()
				})

				// Créer un objet au format { horse_id: horse_nom } pour formatter les couples plus facilement
				let formatted = {}
				horses.forEach(horse => {
					formatted[horse.horse_id] = horse.horse_nom
				})

				const groupIdx = question_webservice.question_questiondata.data.length
				const groupLabel = `${season.season_label} - ${this.$t('gynecologie.as_donneuse')}`
				question_webservice.question_questiondata.data.push({
					groupLabel: groupLabel,
					values: []
				})

				// Formatter les couples et ajouter les options
				season_mare_stallions.forEach(sms => {
					const stallion = formatted[sms.seasonmarestallion_horse]
					const mare_id = season_mares_horses[sms.seasonmarestallion_seasonmare]
					const mare = formatted[mare_id]
					const porteuse_id = season_mares_horses[sms.seasonmarestallion_porteuse]
					const porteuse = formatted[porteuse_id]

					if(!stallion || !mare) {
						return
					}

					let label = ''
					if(porteuse) {
						label = `${porteuse} x ${stallion}`
					}
					else {
						label = `${mare} x ${stallion}`
					}

					if(sms.seasonmarestallion_status) {
						label += ` / ${sms.seasonmarestallion_status}`
					}

					if(sms.seasonmarestallion_num_dps) {
						label += ` / ${sms.seasonmarestallion_num_dps}`
					}

					if(sms.seasonmarestallion_dds) {
						const diff = this.calculDifference(sms.seasonmarestallion_dds, new Date())
						label += ` / ${diff} ` + this.getTrad('monte.days_since_last_covering')
					}

					const id = `${groupLabel}-${label}`
					question_webservice.question_season_mare_stallions[id] = sms.seasonmarestallion_id
					const value = {
						uniqueIdentifier: id,
						group: groupLabel,
						value: label
					}

					question_webservice.question_questiondata.data[groupIdx].values.push(value)

					if(select_default && default_stallion && sms.seasonmarestallion_id === default_stallion.seasonmarestallion_id) {
						question_webservice.question_default_value = value
					}
				})

				question_webservice.question_questiondata.data[groupIdx].values.sort()
			}
		},

		// Récupérer les couples dont je suis la porteuse
		async loadSeasonPorteuseStallions(season, season_mare, question_webservice, select_default) {
			if(!season_mare) {
				return
			}

			const season_mare_stallions = await this.$storage.db.t('season_mare_stallion')
			.then(table => {
				return table.where('seasonmarestallion_porteuse').equals(season_mare.seasonmare_id)
					.toArray()
			})

			// Récupérer les IDs des season_mare des donneuse et les IDs des étalons et construire un objet représentant les couples
			const donneuse_seasonmare_ids = []
			const stallion_ids = []
			let default_stallion

			season_mare_stallions.forEach(sms => {
				donneuse_seasonmare_ids.push(sms.seasonmarestallion_seasonmare)
				stallion_ids.push(sms.seasonmarestallion_horse)

				if(sms.seasonmarestallion_default) {
					default_stallion = sms
				}
			})

			// Récupérer les IDs des donneuses à partir des season_mare et se souvenir de la correspondance seasonmare_id => horse_id
			let season_mares_horses = {}
			const mare_ids = await this.$storage.db.t('season_mare')
			.then(table => {
				return table.where('seasonmare_id').anyOf(donneuse_seasonmare_ids).toArray()
			})
			.then(season_mares => {
				return season_mares.map((sm) => {
					season_mares_horses[sm.seasonmare_id] = sm.seasonmare_horse

					return sm.seasonmare_horse
				})
			})

			if (mare_ids.length > 0 || stallion_ids.length > 0) {
				// Récupérer tous les chevaux
				const horses = await this.$storage.db.t('horse')
				.then(table => {
					return table.where('horse_id').anyOf(stallion_ids.concat(mare_ids)).and(h => h.horse_inactive == 0).toArray()
				})

				// Créer un objet au format { horse_id: horse_nom } pour formatter les couples plus facilement
				let formatted = {}
				horses.forEach(horse => {
					formatted[horse.horse_id] = horse.horse_nom
				})

				const groupIdx = question_webservice.question_questiondata.data.length
				const groupLabel = `${season.season_label} - ${this.$t('gynecologie.as_porteuse')}`
				question_webservice.question_questiondata.data.push({
					groupLabel: groupLabel,
					values: []
				})

				// Formatter les couples et ajouter les options
				season_mare_stallions.forEach(sms => {
					const stallion = formatted[sms.seasonmarestallion_horse]
					const mare_id = season_mares_horses[sms.seasonmarestallion_seasonmare]
					const mare = formatted[mare_id]

					if(!stallion || !mare) {
						return
					}

					let label = `${mare} x ${stallion}`

					if(sms.seasonmarestallion_status) {
						label += ` / ${sms.seasonmarestallion_status}`
					}

					if(sms.seasonmarestallion_num_dps) {
						label += ` / ${sms.seasonmarestallion_num_dps}`
					}

					if(sms.seasonmarestallion_dds) {
						const diff = this.calculDifference(sms.seasonmarestallion_dds, new Date())
						label += ` / ${diff} ` + this.getTrad('monte.days_since_last_covering')
					}

					const id = `${groupLabel}-${label}`
					question_webservice.question_season_mare_stallions[id] = sms.seasonmarestallion_id
					const value = {
						uniqueIdentifier: id,
						group: groupLabel,
						value: label
					}

					question_webservice.question_questiondata.data[groupIdx].values.push(value)

					if(select_default && default_stallion && sms.seasonmarestallion_id === default_stallion.seasonmarestallion_id) {
						question_webservice.question_default_value = value
					}
				})

				question_webservice.question_questiondata.data[groupIdx].values.sort()
			}
		},

		async loadOtherStallions(question_webservice) {
			const horses = await this.$storage.db.t('horse').then(table => {
				return table.where('horse_stallion').equals(1).and(h => h.horse_inactive == 0).toArray()
			})

			if(horses.length > 0) {
				const groupIdx = question_webservice.question_questiondata.data.length
				const groupLabel = this.$t('gynecologie.autres_etalons')
				question_webservice.question_other_stallions = {}
				question_webservice.question_questiondata.data.push({
					groupLabel: groupLabel,
					values: []
				})

				horses.forEach(horse => {
					const id = `other-${horse.horse_id}`
					question_webservice.question_other_stallions[id] = horse.horse_id
					question_webservice.question_questiondata.data[groupIdx].values.push({
						uniqueIdentifier: id,
						group: groupLabel,
						value: horse.horse_nom
					})
				})

				question_webservice.question_questiondata.data[groupIdx].values.sort()
			}
		},

		async seasonMareStallionPorteuseMaybePrevious(question_webservice, params) {
			question_webservice.question_questiondata.data = []

			const mare_id = params.horses_ids.length ? params.horses_ids[0] : null
			if(mare_id) {
				// Correspondance entre le nom et le seasonmarestallion_id
				question_webservice.question_season_mare_stallions = {}

				// Infos de la saison en cours
				let eleven = new Date(params.actes_date)
				eleven.setMonth(params.actes_date.getMonth() - 11)
				let current_season = await this.getCurrentSeason(eleven)
				let current_season_mare = null

				// if (current_season.length > 0) {
				// 	current_season = current_season[0]

				// 	current_season_mare = await this.getCurrentSeasonMare(current_season.season_id, mare_id)
				// }

				await Common.asyncForEach(current_season, async (season) => {
					current_season_mare = await this.getCurrentSeasonMare(season.season_id, mare_id)

					// Infos de la saison précédente
					let lastYearDate = new Date(eleven.getTime())
					lastYearDate.setMonth(lastYearDate.getMonth() - 12)
					let previous_season = await this.getCurrentSeason(lastYearDate)
					let previous_season_mare = null

					if (previous_season.length > 0) {
						previous_season = previous_season[0]

						previous_season_mare = await this.getCurrentSeasonMare(previous_season.season_id, mare_id)
					} 
					else {
						previous_season = null
					}

					// Etalons de la saison en cours
					if (season && current_season_mare) {
						// Charger les étalons de la saison en cours
						await this.loadSeasonStallions(season, current_season_mare, question_webservice, true)
					}

					// Couples en tant que porteuse de la saison en cours
					if(season) {
						// Charger les couples dont elle pourrait etre la porteuse
						await this.loadSeasonPorteuseStallions(season, current_season_mare, question_webservice, !question_webservice.question_default_value)
					}

					// Etalons de la saison précédente
					if (previous_season && previous_season_mare) {
						// Charger les étalons de la saison en cours
						await this.loadSeasonStallions(previous_season, previous_season_mare, question_webservice, !question_webservice.question_default_value)
					}

					// Couples en tant que porteuse de la saison précédente
					if(previous_season) {
						await this.loadSeasonPorteuseStallions(previous_season, previous_season_mare, question_webservice, !question_webservice.question_default_value)
					}
				})
			}

			if(!question_webservice.question_default_value && question_webservice.question_questiondata.data.length > 0 && question_webservice.question_questiondata.data[0].values.length > 0) {
				question_webservice.question_default_value = question_webservice.question_questiondata.data[0].values[0]
			}

			return [ question_webservice ]
		},

		async lieu(question_webservice, params) {
			const lieux = await this.getOtherLieux()
            question_webservice.question_questiondata.data = lieux.map(lieu => lieu.lieu_label)
            question_webservice.question_lieu = lieux

            return [ question_webservice ]
		},

		calculDifference(date1, date2) {
			date1.setHours(0,0,0,0);
			date2.setHours(0,0,0,0);
			let Difference_In_Time = date2.getTime() - date1.getTime(); 
			let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
			return Math.round(Difference_In_Days);
		}
    }
}

export default LocalWBSMixin
