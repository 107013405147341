<template>
    <div class="col-sm-8">
        <b-form-group>
            <!-- :id="'checkbox-group-'+q_id" -->
            <b-form-checkbox-group
                :id="code"
                :name="code"
                v-model="selected"
                text-field="label"
                value-field="label"
                class="row"
                stacked
            >
                <template v-for="(opt, key) in options">
                    <div v-if="opt.id == '-'" class="col-auto" :key="key"></div>
                    <b-form-checkbox
                        v-else
                        :value="opt"
                        class="col-sm-4 col-12"
                        :key="key"
                    >
                        {{ opt.label }}
                    </b-form-checkbox>
                </template>
            </b-form-checkbox-group>
        </b-form-group>
        <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" pulse />
    </div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import Navigation from '@/mixins/Navigation.js'
    import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: "MultiCheckboxInput",
        mixins: [Navigation],
        props:[
            'placeholder',
            'values',
            'answer',
            'q_id',
            'answer_given',
            'required',
            'ws',
            'code'
        ],
        data() {
            return {
                selected: [],
                options: [],
                isLoading: false,
                local_values: null,

                events_tab: {
                    'inputs::resetChecked': this.resetCheck
                }
            }
        },
		mounted() {
            this.init_component()
		},
		methods: {
            async init_component() {
                if(typeof(this.values) == "string") {
                    this.local_values = JSON.parse(this.values)
                }
                else {
                    this.local_values = _cloneDeep(this.values)
                }

                const type = this.local_values.type

                if(type == "data") {
                    if(this.local_values.type == "data") {
                        let translated_responses = []
                        this.local_values.data.forEach((option, key) => {
                            let label = null
                            label = this.getTrad('reponses.' + [option]).includes('reponses.') ? option : this.getTrad('reponses.' + [option])
                            translated_responses.push({
                                id: option,
                                label: label
                            })
                        })

                        this.options = translated_responses
                    }
                }

                this.init_input()
                this.isLoading = false
            },
            init_input() {
                if(this.answer_given && this.answer_given.val!==null && this.answer_given.key == this.q_id) {
                    let selected = []
                    this.answer_given.val.forEach(val => {
                        if(val) {
                            selected.push({id: val, label: Vue.i18n.translate('reponses.' + val).includes('reponses.') ? val : Vue.i18n.translate('reponses.' + val)})
                        }
                    })
                    this.selected = selected
                }
            },
            resetCheck() {
                this.selected = []
            }
        },
        computed: {

        },
        watch: {
            'selected' (val) {
                if(val) {
                    let new_val = val.map(v => v.id)
                    this.$emit('update:answer', { key: this.q_id, val: new_val })
                }
                else {
                    this.$emit('update:answer', { key: this.q_id, val: val })
                }
            },
            values: {
                handler(prev, val){
                    if(JSON.stringify(prev) != JSON.stringify(val) || JSON.stringify(val.data) != JSON.stringify(this.local_values.data)) {
                        this.init_component()
                    }
                },
                deep: true
            }
        }
    }
</script>
